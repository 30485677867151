/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/crypto-js@4.1.1/core.min.js
 * - /npm/crypto-js@4.1.1/md5.min.js
 * - /npm/crypto-js@4.1.1/hmac.min.js
 * - /npm/crypto-js@4.1.1/hmac-md5.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
